import React from 'react'
import PropTypes from 'prop-types'

function Details({ items }) {
  return (
    <ul className="detailsList py-4 text-sm">
      {items.map((item, i) => (
        <li key={`detail_${i}`} className="py-2">
          <div>{item}</div>
        </li>
      ))}
    </ul>
  )
}

export default Details

Details.propTypes = {
  items: PropTypes.array,
}
